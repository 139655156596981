import { createSignal, onMount, Show, splitProps } from "solid-js";
import { TextField, Select, MenuList, MenuItem, ListItemIcon, ListItemText, Divider } from "@suid/material";
import { CountryType, countries } from "../DataLists/DataCountryList";
import { SelectChangeEvent } from "@suid/material/Select";
import { getText } from '../SmallComponents/Translate'

import toast, { Toaster } from 'solid-toast';

interface PhoneNumberInputProps {
    id: string;
    label?: string;
    startValue: string;
    startPrefix: string;
    valueOnChange: (value: string) => void;
    prefixOnChange: (value: string) => void;
}

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    //  const [countryPrefix, setCountryPrefix] = createSignal(props.startPrefix);
    //  const [phoneNumber, setPhoneNumber] = createSignal(props.startValue);

    const [open, setOpen] = createSignal(false);


    const [filteredOptions, setFilteredOptions] = createSignal<CountryType[]>([]);

    const handlePhoneInput = (value: string) => {
        const regex = /^[0-9+()]*([0-9+()]\s?)*[0-9+()]*$/;
        if (regex.test(value)) {
            const leftBracketCount = (value.match(/\(/g) || []).length;
            const rightBracketCount = (value.match(/\)/g) || []).length;
            if (leftBracketCount <= 1 && rightBracketCount <= 1) {
                //   setPhoneNumber(value);
                props.valueOnChange(value)
            }
        }
    };

    const handlePrefixChangeTxt = (value: string) => {

        // setCountryPrefix(value);
        props.prefixOnChange(value)
    };
    const handlePrefixChange = (event: SelectChangeEvent) => {
        const value = event.target.value
        handlePrefixChangeTxt(value)

    };

    onMount(async () => {
        // setPhoneNumber(props.startValue);

        const suggestedCountries = countries.filter(country => country.suggestedPrefix);
        setFilteredOptions(suggestedCountries);

    })
    const handleFocus = () => {
        // if (phoneNumber().length <2) 
        //     setPhoneNumber("");
    };
    const handleBlur = () => {
        //  if (phoneNumber() === "") {

        //    setPhoneNumber(" ");
        //  }
    }
    const txtAddPrefix = getText("smallcomponent", "addprefix")
    const txtOthers = getText("default", "others")

    return (
        <TextField
            required
            id={props.id}
            label={props.label}
            variant="outlined"
            size="small"

            value={props.startValue}
            onChange={(event, value) => { handlePhoneInput(value) }}

            onFocus={handleFocus}
            onBlur={handleBlur}

            InputProps={{


                startAdornment: (
                    <Select
                        open={open()}
                        value={props.startPrefix}

                        onChange={(event) => { handlePrefixChange }}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        variant="standard"
                        disableUnderline
                        renderValue={(selected) => `+${selected}`}
                    >
                        {filteredOptions().map((option, index) => (
                            <MenuItem
                                value={option.phone}
                                onClick={() => {
                                    handlePrefixChangeTxt(option.phone as string);

                                    setOpen(false);
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {option.label} ({option.code}) +{option.phone}
                                </ListItemText>
                            </MenuItem>
                        ))}
                        <Divider />

                        <MenuItem value={"Others"}>{txtOthers()}</MenuItem>

                    </Select>
                ),
            }}
        />
    );
}

export { PhoneNumberInput }
